import Vue from 'vue';
import Router from 'vue-router';
import VueMeta from 'vue-meta';
import Store from '@/store/store';
import { titleCase, kebabCaseToTitleCase } from './helper';
import { getTrialTime } from './helper/apiHelpers';
import { adminDashboardOnlyAccountPermissions } from '@/constants/pages.ts';

import screenPermissionListJson from '@/json/screenPermissionList';
import localForage from 'localforage';

import api from '@/api';

import { isCurrentUserAdvertisingAdmin } from '@/helper/index';
Vue.use(Router);
Vue.use(VueMeta);

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    {
      // =============================================================================
      // MAIN LAYOUT ROUTES
      // =============================================================================
      path: '',
      name: 'main',
      component: () => import('./layouts/main/Main.vue'),
      children: [
        // =============================================================================
        // Theme Routes
        // =============================================================================
        /*{
          path: '/',
          redirect: '/dashboard/analytics'
        },*/
        // {
        //   path: '/admin-dashboard',
        //   name: 'admin-dashboard',
        //   component: () => import('./views/admin-panel/Dashboard.vue'),
        //   meta: {
        //     pageTitle: 'Admin Dashboard',
        //     rule: 'admin',
        //     permissionName: 'display_admin_panel',
        //     pathURL: '/admin-dashboard',
        //   },
        // },
        {
          path: '/admin-dashboard',
          name: 'admin-dashboard',
          component: () => import('./views/admin-panel/TableList.vue'),
          meta: {
            pageTitle: 'Account List',
            rule: 'admin',
            permissionName: 'display_admin_panel',
            pathURL: '/admin-dashboard',
          },
        },
        {
          path: '/admin-dashboard/daily-task',
          name: 'admin-dashboard/daily-task',
          component: () => import('./views/admin-panel/DailyTask.vue'),
          meta: {
            pageTitle: 'Daily Tasks',
            rule: 'admin',
            permissionName: 'display_admin_panel',
            pathURL: '/admin-dashboard/daily-task',
          },
        },
        {
          path: '/admin-dashboard/:page',
          name: 'admin-dashboard/pages',
          component: () => import('./views/admin-panel/TableList.vue'),
          meta: {
            rule: 'admin',
            permissionName: 'display_admin_panel',
            pathURL: '/admin-dashboard/:page',
            pageTitle: 'Page Name',
          },
        },
        {
          path: '/admin-dashboard/:tableName-list/:tableName/:accountId',
          name: 'admin-dashboard/details',
          component: () => import('./views/admin-panel/TableDetailsEdit.vue'),
          children: [
            {
              path: '/admin-dashboard/:tableName-list/:tableName/:accountId/:secondTableName-list/:secondTableName/:secondAccountId',
              name: 'admin-dashboard/details/infoDetails',
              component: () =>
                import('./views/admin-panel/TableDetailsEdit.vue'),
              meta: {
                breadcrumb: [
                  { title: 'Home', url: '/admin-dashboard/account-list' },
                  {
                    title: 'Admin Dashboard',
                    url: '/admin-dashboard/account-list',
                  },
                  {
                    title: 'Table List',
                    url: '/admin-dashboard/pages',
                  },
                  {
                    title: 'Table Detail',
                    url: '/admin-dashboard/details',
                  },
                  {
                    title: 'Table',
                    active: true,
                  },
                ],
                pageTitle: 'Admin Dashboard',
                rule: 'admin',
                permissionName: 'display_admin_panel',
                pathURL: '/admin-dashboard/details/infoDetails',
              },
            },
          ],
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/admin-dashboard/account-list' },
              {
                title: 'Admin Dashboard',
                url: '/admin-dashboard/account-list',
              },
              {
                title: 'Table List',
                active: false,
                url: '/admin-dashboard/pages',
              },
              {
                title: 'Table Detail',
                active: true,
              },
            ],
            pageTitle: 'Admin Dashboard',
            rule: 'admin',
            permissionName: 'display_admin_panel',
            pathURL: '/admin-dashboard/details',
          },
        },
        {
          path: '/',
          name: 'dashboard',
          component: () => import('./views/DashboardAnalytics.vue'),
          meta: {
            rule: 'editor',
            permissionName: 'display_store_overview',
            pathURL: '/',
          },
        },
        {
          path: '/success-payment',
          name: 'success-payment',
          component: () => import('./views/DashboardAnalytics.vue'),
          meta: {
            rule: 'editor',
            permissionName: 'display_start_monthly_plan_button',
            pathURL: '/success-payment',
          },
        },
        {
          path: '/error-payment',
          name: 'error-payment',
          component: () => import('./views/components/repricer/main'),
          meta: {
            rule: 'editor',
            permissionName: 'display_start_monthly_plan_button',
            pathURL: '/error-payment',
          },
        },
        {
          path: '/progress-payment',
          name: 'progress-payment',
          component: () => import('./views/DashboardAnalytics.vue'),
          meta: {
            rule: 'editor',
            permissionName: 'display_start_monthly_plan_button',
            pathURL: '/progress-payment',
          },
        },
        {
          path: '/again-payment',
          name: 'again-payment',
          component: () => import('./views/DashboardAnalytics.vue'),
          meta: {
            rule: 'editor',
            permissionName: 'display_start_monthly_plan_button',
            pathURL: '/again-payment',
          },
        },
        {
          path: '/daily-sales',
          name: 'daily-sales',
          component: () =>
            import('./views/components/daily-sales/main-daily.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Daily Sales', active: true },
            ],
            pageTitle: 'Operations Center',
            rule: 'admin',
            permissionName: 'display_operations_center',
            pathURL: '/daily-sales',
          },
        },
        {
          path: '/segmentation',
          name: 'segmentation',
          component: () =>
            import('./views/components/asin-segmentation/index.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'ASIN Segmentation', active: true },
            ],
            pageTitle: 'Operations Center',
            rule: 'editor',
            permissionName: 'display_operations_center',
            pathURL: '/segmentation',
          },
        },
        {
          path: '/yoy-daily-sales',
          name: 'YoY Daily Sales',
          component: () =>
            import('./views/components/yoy-daily-sales/main.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'YoY Daily Sales', active: true },
            ],
            pageTitle: 'Operations Center',
            rule: 'editor',
            permissionName: 'display_operations_center',
            pathURL: '/yoy-daily-sales',
          },
        },
        {
          path: '/top-asins',
          name: 'top-asins',
          component: () =>
            import('./views/components/top-ten-sku/main-topasin.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'TOP ASINs', active: true },
            ],
            pageTitle: 'Operations Center',
            rule: 'editor',
            permissionName: 'display_operations_center',
            pathURL: '/top-asins',
          },
        },
        {
          path: '/reports',
          name: 'reports',
          component: () =>
            import('./views/components/reporting-dashboard/Dashboard.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Reports', active: true },
            ],
            pageTitle: 'Operations Center',
            rule: 'editor',
            permissionName: 'display_operations_center',
            pathURL: '/reports',
          },
        },
        {
          path: '/advertising',
          name: 'advertisingDashboard',
          component: () =>
            import('./views/components/advertising/dashboard/main'),
          meta: {
            rule: 'editor',
            permissionName: 'display_vendor_management',
            pathURL: '/advertising',
          },
        },
        {
          path: '/advertising',
          name: 'advertisingDashboard',
          component: () =>
            import('./views/components/advertising/dashboard/main'),
          meta: {
            rule: 'editor',
            permissionName: 'display_walmart_management',
            pathURL: '/advertising',
          },
        },
        {
          path: '/advertisingPerformanceTrends',
          name: 'advertisingPerformanceTrends',
          component: () =>
            import(
              './views/components/advertising/dashboard/AdvertisingPerformanceTrends'
            ),
          meta: {
            rule: 'editor',
            permissionName: 'display_advertising',
            pathURL: '/AdvertisingPerformanceTrends',
          },
        },
        {
          path: '/advertising-analytics',
          name: 'advertisingAnalytics',
          component: () =>
            import('./views/components/advertising/analytics/main'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Advertising Analytics', active: false },
            ],
            pageTitle: 'Advertising Analytics',
            rule: 'editor',
            permissionName: 'display_advertising',
            pathURL: '/advertising-analytics',
          },
        },
        {
          path: '/advertising-ai-settings',
          name: 'advertisingRules',
          component: () =>
            import('./views/components/advertising/automationSettings/main'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Advertising AI Settings', active: false },
            ],
            pageTitle: 'Advertising AI Settings',
            rule: 'editor',
            permissionName: 'display_advertising',
            pathURL: '/advertising-ai-settings',
          },
        },
        {
          path: '/advertising-ai-decisions',
          name: 'advertisingRuleResults',
          component: () =>
            import('./views/components/advertising/ruleResults/ruleResults'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Advertising AI Decisions', active: false },
            ],
            pageTitle: 'Advertising AI Decisions',
            rule: 'editor',
            permissionName: 'display_advertising',
            pathURL: '/advertising-ai-decisions',
          },
        },
        {
          path: '/advertising-reports',
          name: 'advertisingReports',
          component: () =>
            import('./views/components/advertising/reports/main'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Advertising Reports', active: false },
            ],
            pageTitle: 'Advertising Reports',
            rule: 'editor',
            permissionName: 'display_advertising',
            pathURL: '/advertising-reports',
          },
        },
        {
          path: '/advertising-audit-reports',
          name: 'advertisingAuditReports',
          component: () =>
            import('./views/components/advertising/auditReport/index.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Advertising Audit Reports', active: false },
            ],
            pageTitle: 'Advertising Audit Reports',
            rule: 'editor',
            permissionName: 'display_advertising',
            pathURL: '/advertising-audit-reports',
          },
        },
        {
          path: '/advertising-search-term',
          name: 'advertisingSearchTerm',
          component: () =>
            import('./views/components/advertising/searchTermReport/index'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Search Term Analytics', active: false },
            ],
            pageTitle: 'Search Term Analytics',
            rule: 'editor',
            permissionName: 'display_advertising',
            pathURL: '/advertising-search-term',
          },
        },
        {
          path: '/aggregated-stores-view',
          name: 'allStoresView',
          component: () => import('./views/DashboardAllStoresView'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Aggregated Stores View', active: false },
            ],
            pageTitle: 'Aggregated Stores View',
            rule: 'editor',
            permissionName: 'display_aggregated_stores_view',
            pathURL: '/aggregated-stores-view',
          },
        },
        {
          path: '/replenishment',
          name: 'replenishment',
          component: () => import('./views/components/replenishment/main'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Replenishment', active: true },
            ],
            pageTitle: 'Inventory Management',
            rule: 'editor',
            permissionName: 'display_inventory_management',
            pathURL: '/replenishment',
          },
        },
        {
          path: '/inventory-health-report',
          name: 'InventoryHealthReport',
          component: () =>
            import('./views/components/InventoryHealthReport/main'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Inventory Health Report', active: true },
            ],
            pageTitle: 'Inventory Health Report',
            rule: 'editor',
            permissionName: 'display_inventory_management',
            pathURL: '/inventory-health-report',
          },
        },
        {
          path: '/SKU-analytics-actions',
          name: 'SKUAnalyticsActions',
          component: () => import('./views/components/competition/main'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Pricing Actions and Analytics', active: true },
            ],
            pageTitle: 'Pricing Actions and Analytics',
            rule: 'editor',
            permissionName: 'display_pricing_management',
            pathURL: '/pricing-management',
          },
        },
        {
          path: '/SKU-performance',
          name: 'SKUPerformance',
          component: () =>
            import('./views/components/sku-performance/table-list'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'SKU Performance', active: true },
            ],
            pageTitle: 'SKU Performance',
            rule: 'editor',
            permissionName: 'display_sku_performance',
            pathURL: '/SKU-performance',
          },
        },
        {
          path: '/SKU-performance-details',
          name: 'SKUPerformanceDetails',
          component: () =>
            import('./views/components/sku-performance/sku-details'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'SKU Performance', url: '/SKU-performance' },
              { title: 'SKU Details', active: true },
            ],
            pageTitle: 'SKU Performance Details',
            rule: 'editor',
            permissionName: 'display_sku_performance',
            pathURL: '/SKU-performance/details',
          },
        },
        {
          path: '/returns-management',
          name: 'ReturnManagement',
          component: () => import('./views/components/returnManagement/main'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Returns Management', active: true },
            ],
            pageTitle: 'Inventory Management',
            rule: 'editor',
            permissionName: 'display_inventory_management',
            pathURL: '/returns-management',
          },
        },
        {
          path: '/receive-orders',
          name: 'Receive Orders',
          component: () => import('./views/components/purchaseOrder/list'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Receive Orders', active: true },
            ],
            pageTitle: 'Inventory Management',
            rule: 'editor',
            permissionName: 'display_inventory_management',
            pathURL: '/receive-orders',
          },
        },
        {
          path: '/new-receive-order',
          name: 'new-receive-order',
          component: () =>
            import('./views/components/purchaseOrder/detail.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              {
                title: 'Receive Orders',
                active: false,
                url: '/receive-orders',
              },
              { title: 'New Receive Order', active: true },
            ],
            pageTitle: 'Inventory Management',
            rule: 'editor',
            permissionName: 'display_inventory_management',
            pathURL: '/new-receive-order',
          },
        },
        {
          path: '/eva-3pl-inventory',
          name: 'eva-3pl-inventory',
          component: () => import('./views/components/eva3pl/main.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Eva 3PL Inventory', active: true },
            ],
            pageTitle: 'Inventory Management',
            rule: 'editor',
            permissionName: 'display_inventory_management',
            pathURL: '/eva-3pl-inventory',
          },
        },
        {
          path: '/vendor-dashboard',
          name: 'dashboard',
          component: () =>
            import('./views/components/vendor/DashboardAnalytics.vue'),
          meta: {
            rule: 'editor',
            permissionName: 'display_vendor_management',
            pathURL: '/vendor-dashboard',
          },
        },
        {
          path: '/vendor-asin-performance',
          name: 'ASINPerformance',
          component: () =>
            import('./views/components/vendor/asinPerformance/table-list.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'ASIN Performance', active: true },
            ],
            pageTitle: 'Vendor Management',
            rule: 'admin',
            permissionName: 'display_vendor_management',
            pathURL: '/vendor-asin-performance',
          },
        },
        {
          path: '/vendor-asin-performance-details',
          name: 'ASINPerformance',
          component: () =>
            import(
              './views/components/vendor/asinPerformance/asin-details.vue'
            ),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'ASIN Performance', url: '/vendor-asin-performance' },
              { title: 'ASIN Performance Details', active: true },
            ],
            pageTitle: 'Vendor Management',
            rule: 'admin',
            permissionName: 'display_vendor_management',
            pathURL: '/vendor-asin-performance-details',
          },
        },
        {
          path: '/vendor-management',
          name: 'VendorManagement',
          component: () => import('./views/components/vendor/vendor-details'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Retail Analytics Details', active: true },
            ],
            pageTitle: 'Vendor Management',
            rule: 'admin',
            permissionName: 'display_vendor_management',
            pathURL: '/vendor-management',
          },
        },
        {
          path: '/vendor-realtime',
          name: 'VendorManagement',
          component: () =>
            import('./views/components/vendor/RealTime/realTime.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Detailed Real Time Sales', active: true },
            ],
            pageTitle: 'Vendor Management',
            rule: 'admin',
            permissionName: 'display_vendor_management',
            pathURL: '/vendor-realtime',
          },
        },
        {
          path: '/vendor-purchase-orders',
          name: 'VendorManagement',
          component: () =>
            import('./views/components/vendor/PurchaseOrder/purchaseOrder.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Purchase Orders', active: true },
            ],
            pageTitle: 'Vendor Management',
            rule: 'admin',
            permissionName: 'display_vendor_management',
            pathURL: '/vendor-purchase-orders',
          },
        },
        {
          path: '/vendor-reimbursement-analytics',
          name: 'VendorReimbursementManagement',
          component: () =>
            import('./views/components/vendor/Reimburse/tableList.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Recovery Dashboard', active: true },
            ],
            pageTitle: 'Recovery Dashboard',
            rule: 'editor',
            permissionName: 'display_vendor_management',
            pathURL: '/vendor-reimbursement-analytics',
          },
        },
        {
          path: '/vendor-invoice',
          name: 'VendorReimbursementManagement',
          component: () =>
            import('./views/components/vendor/Invoice/invoice.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Invoice Analytics', active: true },
            ],
            pageTitle: 'Vendor Management',
            rule: 'admin',
            permissionName: 'display_vendor_management',
            pathURL: '/vendor-invoice',
          },
        },
        {
          path: '/vendor-search-product',
          name: 'SearchProduct',
          component: () =>
            import('./views/components/compititionAnalytics/main-compitition'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Search Product', active: false },
            ],
            pageTitle: 'Search Product',
            rule: 'editor',
            permissionName: 'display_vendor_management',
            pathURL: '/vendor-search-product',
          },
        },
        {
          path: '/vendor-disputes',
          name: 'VendorReimbursementManagement',
          component: () =>
            import('./views/components/vendor/Disputes/disputes.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Dispute Analytics', active: true },
            ],
            pageTitle: 'Vendor Management',
            rule: 'admin',
            permissionName: 'display_vendor_management',
            pathURL: '/vendor-disputes',
          },
        },
        {
          path: '/vendor-check-credentials',
          name: 'check-credentials',
          component: () => import('./views/pages/VendorCheckCredentials.vue'),
          meta: {
            rule: 'editor',
          },
        },
        {
          path: '/vendor-check-credentials-eva',
          name: 'check-credentials',
          component: () =>
            import('./views/pages/VendorCheckCredentialsEva.vue'),
          meta: {
            rule: 'editor',
          },
        },
        /////////// walmart
        {
          path: '/walmart-dashboard',
          name: 'dashboard',
          component: () =>
            import('./views/components/walmart/DashboardAnalytics.vue'),
          meta: {
            rule: 'editor',
            permissionName: 'display_vendor_management',
            pathURL: '/walmart-dashboard',
          },
        },
        {
          path: '/walmart-sku-performance',
          name: 'SKUPerformance',
          component: () =>
            import('./views/components/walmart/skuPerformance/table-list.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/walmart-dashboard' },
              { title: 'SKU Performance', active: true },
            ],
            pageTitle: 'SKU Performance',
            rule: 'admin',
            permissionName: 'display_walmart_management',
            pathURL: '/walmart-sku-performance',
          },
        },
        {
          path: '/walmart-product-management',
          name: 'ProductManagement',
          component: () =>
            import('./views/components/walmart/productManagement/main.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/walmart-dashboard' },
              { title: 'Product Management', active: true },
            ],
            pageTitle: 'Product Management',
            rule: 'admin',
            permissionName: 'display_walmart_management',
            pathURL: '/walmart-product-management',
          },
        },
        {
          path: '/walmart-inventory-health-report',
          name: 'InventoryHealthReport',
          component: () =>
            import(
              './views/components/walmart/inventoryManagement/healthReport/main.vue'
            ),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/walmart-dashboard' },
              { title: 'Inventory Health Report', active: true },
            ],
            pageTitle: 'Inventory Health Report',
            rule: 'editor',
            permissionName: 'display_walmart_management',
            pathURL: '/walmart-inventory-health-report',
          },
        },
        {
          path: '/walmart-inventory-health-report',
          name: 'InventoryHealthReport',
          component: () =>
            import('./views/components/InventoryHealthReport/main'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/walmart-dashboard' },
              { title: 'Inventory Health Report', active: true },
            ],
            pageTitle: 'Inventory Health Report',
            rule: 'editor',
            permissionName: 'display_walmart_management',
            pathURL: '/walmart-inventory-health-report',
          },
        },
        {
          path: '/walmart-top-search-trends',
          name: 'TopSearchTrends',
          component: () =>
            import('./views/components/walmart/topSearchTrends/main.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/walmart-dashboard' },
              { title: 'Top Search Trends', active: true },
            ],
            pageTitle: 'Top Search Trends',
            rule: 'admin',
            permissionName: 'display_walmart_management',
            pathURL: '/walmart-top-search-trends',
          },
        },
        {
          path: '/walmart-feed-status',
          name: 'FeedStatus',
          component: () =>
            import('./views/components/walmart/feedStatus/main.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/walmart-dashboard' },
              { title: 'Feed Status', active: true },
            ],
            pageTitle: 'Feed Status',
            rule: 'admin',
            permissionName: 'display_walmart_management',
            pathURL: '/walmart-feed-status',
          },
        },
        {
          path: '/walmart-logs',
          name: 'ApplicationLogs',
          component: () => import('./views/components/walmart/logs/main.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/walmart-dashboard' },
              { title: 'Application LOGs', active: true },
            ],
            pageTitle: 'Application LOGs',
            rule: 'admin',
            permissionName: 'display_walmart_management',
            pathURL: '/walmart-logs',
          },
        },
        {
          path: '/walmart-orders',
          name: 'Orders',
          component: () =>
            import('./views/components/walmart/orders/ordersTable'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/walmart-dashboard' },
              { title: 'Orders', active: true },
            ],
            pageTitle: 'Orders',
            rule: 'admin',
            permissionName: 'display_walmart_management',
            pathURL: '/walmart-orders',
          },
        },
        {
          path: '/walmart-returns',
          name: 'Returns',
          component: () =>
            import('./views/components/walmart/returns/returnsTable'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/walmart-dashboard' },
              { title: 'Returns', active: true },
            ],
            pageTitle: 'Returns',
            rule: 'admin',
            permissionName: 'display_walmart_management',
            pathURL: '/walmart-returns',
          },
        },
        {
          path: '/walmart-search-product',
          name: 'SearchProduct',
          component: () =>
            import('./views/components/walmart/searchProduct/main.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/walmart-dashboard' },
              { title: 'Search Product', active: false },
            ],
            pageTitle: 'Search Product',
            rule: 'editor',
            permissionName: 'display_walmart_management',
            pathURL: '/walmart-search-product',
          },
        },
        {
          path: '/walmart-advertising-dashboard',
          name: 'walmartAdvertisingAnalytics',
          component: () =>
            import(
              './views/components/walmart/advertising/dashboard/index.vue'
            ),
          meta: {
            rule: 'editor',
            permissionName: 'display_walmart_management',
            pathURL: '/walmart-advertising-dashboard',
          },
        },
        {
          path: '/walmart-advertising-analytics',
          name: 'walmartAdvertisingAnalytics',
          component: () =>
            import('./views/components/walmart/advertising/index.vue'),
          meta: {
            rule: 'editor',
            permissionName: 'display_walmart_management',
            pathURL: '/walmart-advertising-analytics',
          },
        },
        {
          path: '/walmart-reports',
          name: 'walmartAdvertisingReports',
          component: () =>
            import(
              './views/components/walmart/advertising/reports/reports.vue'
            ),
          meta: {
            rule: 'editor',
            pageTitle: 'Advertising Reports',
            permissionName: 'display_walmart_management',
            pathURL: '/walmart-reports',
          },
        },
        {
          path: '/search-product',
          name: 'SearchProduct',
          component: () =>
            import('./views/components/compititionAnalytics/main-compitition'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Search Product', active: false },
            ],
            pageTitle: 'Search Product',
            rule: 'editor',
            permissionName: 'display_search_product',
            pathURL: '/search-product',
          },
        },
        {
          path: '/parent-asin-management',
          name: 'ParentManagement',
          component: () =>
            import('./views/components/parentManagement/main.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Parent ASIN Management', active: false },
            ],
            pageTitle: 'Parent ASIN Management',
            rule: 'editor',
            pathURL: '/parent-asin-management',
            permissionName: 'display_parent_asin_management',
          },
        },
        {
          path: '/reimbursement-analytics',
          name: 'Reimbursement Analytics',
          component: () => import('./views/components/reimburse/main'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Reimbursement Analytics', active: true },
            ],
            pageTitle: 'Reimbursement Analytics',
            rule: 'editor',
            permissionName: 'display_reimbursement',
            pathURL: '/reimbursement-analytics',
          },
        },
        {
          path: '/remeasurement-request',
          name: 'Remeasurement Request',
          component: () =>
            import(
              './views/components/remeasurement-request/ManageDimensionsTable'
            ),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Remeasurement Request', active: true },
            ],
            pageTitle: 'Remeasurement Request',
            rule: 'editor',
            permissionName: 'display_reimbursement',
            pathURL: '/remeasurement-request',
          },
        },

        {
          path: '/pricing-management',
          name: 'pricing-management',
          component: () => import('./views/components/repricer/main'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Pricing Management', active: false },
            ],
            pageTitle: 'Pricing Management',
            rule: 'editor',
            permissionName: 'display_pricing_management',
            pathURL: '/pricing-management',
          },
        },
        {
          path: '/integrations',
          name: 'integrations',
          component: () => import('./views/components/integrations/main'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Integrations', active: true },
            ],
            pageTitle: 'Integrations',
            rule: 'editor',
            permissionName: 'display_integrations',
            pathURL: '/integrations',
          },
        },

        {
          path: 'integrations/:marketplace',
          name: 'integration',
          component: () =>
            import('./views/components/integrations/marketplacePage.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Integrations', url: '/integrations' },
              { title: 'Marketplace', active: true },
            ],
            pageTitle: 'Integrations',
            rule: 'editor',
            permissionName: 'display_integrations',
            pathURL: '/integrations/marketplace',
          },
        },
        {
          path: 'integrations/shipstation/connection',
          name: 'integration-connection',
          component: () =>
            import('./views/components/integrations/marketplacePage.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Integrations', url: '/integrations' },
              { title: 'Marketplace', active: true },
            ],
            pageTitle: 'Integrations',
            rule: 'editor',
            permissionName: 'display_integrations',
            pathURL: '/integrations/shipstation/connection',
          },
        },

        {
          path: '/no-permissions',
          name: 'no-permissions',
          component: () => import('./views/pages/NoPermissions.vue'),
          meta: {
            rule: 'editor',
          },
        },

        {
          path: '/help',
          name: 'help',
          component: () => import('./views/components/help/main'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Help', active: false },
            ],
            pageTitle: 'Help',
            rule: 'editor',
            permissionName: 'help',
            pathURL: '/help',
          },
        },

        // =============================================================================
        // Pages Routes
        // =============================================================================
        {
          path: '/settings',
          name: 'settings',
          component: () =>
            import('@/views/pages/user-settings/UserSettings.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Settings', active: false },
            ],
            pageTitle: 'Settings',
            rule: 'editor',
            permissionName: 'display_settings',
            pathURL: '/settings',
          },
        },

        // =============================================================================
        // EXTENSIONS
        // =============================================================================
        // {
        //   path: '/extensions/select',
        //   name: 'extra-component-select',
        //   component: () =>
        //     import('@/views/components/extra-components/select/Select.vue'),
        //   meta: {
        //     breadcrumb: [
        //       { title: 'Home', url: '/' },
        //       { title: 'Extra Components' },
        //       { title: 'Select', active: true },
        //     ],
        //     pageTitle: 'Select',
        //     rule: 'editor',
        //   },
        // },
        // {
        //   path: '/extensions/access-control',
        //   name: 'extra-component-access-control',
        //   component: () =>
        //     import(
        //       '@/views/components/extra-components/access-control/AccessControl.vue'
        //     ),
        //   meta: {
        //     breadcrumb: [
        //       { title: 'Home', url: '/' },
        //       { title: 'Extensions' },
        //       { title: 'Access Control', active: true },
        //     ],
        //     pageTitle: 'Access Control',
        //     rule: 'editor',
        //   },
        // },
        // {
        //   path: '/extensions/i18n',
        //   name: 'extra-component-i18n',
        //   component: () =>
        //     import('@/views/components/extra-components/I18n.vue'),
        //   meta: {
        //     breadcrumb: [
        //       { title: 'Home', url: '/' },
        //       { title: 'Extensions' },
        //       { title: 'I18n', active: true },
        //     ],
        //     pageTitle: 'I18n',
        //     rule: 'editor',
        //   },
        // },
        // {
        //   path: '/extensions/carousel',
        //   name: 'extra-component-carousel',
        //   component: () =>
        //     import('@/views/components/extra-components/carousel/Carousel.vue'),
        //   meta: {
        //     breadcrumb: [
        //       { title: 'Home', url: '/' },
        //       { title: 'Extensions' },
        //       { title: 'Carousel', active: true },
        //     ],
        //     pageTitle: 'Carousel',
        //     rule: 'editor',
        //   },
        // },
        // {
        //   path: '/extensions/clipboard',
        //   name: 'extra-component-clipboard',
        //   component: () =>
        //     import(
        //       '@/views/components/extra-components/clipboard/Clipboard.vue'
        //     ),
        //   meta: {
        //     breadcrumb: [
        //       { title: 'Home', url: '/' },
        //       { title: 'Extensions' },
        //       { title: 'Clipboard', active: true },
        //     ],
        //     pageTitle: 'Clipboard',
        //     rule: 'editor',
        //   },
        // },
        // {
        //   path: '/extensions/star-ratings',
        //   name: 'extra-component-star-ratings',
        //   component: () =>
        //     import(
        //       '@/views/components/extra-components/star-ratings/StarRatings.vue'
        //     ),
        //   meta: {
        //     breadcrumb: [
        //       { title: 'Home', url: '/' },
        //       { title: 'Extensions' },
        //       { title: 'Star Ratings', active: true },
        //     ],
        //     pageTitle: 'Star Ratings',
        //     rule: 'editor',
        //   },
        // },
        // {
        //   path: '/import-export/import',
        //   name: 'import-excel',
        //   component: () =>
        //     import(
        //       '@/views/components/extra-components/import-export/Import.vue'
        //     ),
        //   meta: {
        //     breadcrumb: [
        //       { title: 'Home', url: '/' },
        //       { title: 'Extensions' },
        //       { title: 'Import/Export' },
        //       { title: 'Import', active: true },
        //     ],
        //     pageTitle: 'Import Excel',
        //     rule: 'editor',
        //   },
        // },
        // {
        //   path: '/import-export/export',
        //   name: 'export-excel',
        //   component: () =>
        //     import(
        //       '@/views/components/extra-components/import-export/Export.vue'
        //     ),
        //   meta: {
        //     breadcrumb: [
        //       { title: 'Home', url: '/' },
        //       { title: 'Extensions' },
        //       { title: 'Import/Export' },
        //       { title: 'Export', active: true },
        //     ],
        //     pageTitle: 'Export Excel',
        //     rule: 'editor',
        //   },
        // },
        // {
        //   path: '/import-export/export-selected',
        //   name: 'export-excel-selected',
        //   component: () =>
        //     import(
        //       '@/views/components/extra-components/import-export/ExportSelected.vue'
        //     ),
        //   meta: {
        //     breadcrumb: [
        //       { title: 'Home', url: '/' },
        //       { title: 'Extensions' },
        //       { title: 'Import/Export' },
        //       { title: 'Export Selected', active: true },
        //     ],
        //     pageTitle: 'Export Excel',
        //     rule: 'editor',
        //   },
        // },
      ],
    },
    // =============================================================================
    // FULL PAGE LAYOUTS
    // =============================================================================
    {
      path: '',
      component: () => import('@/layouts/full-page/FullPage.vue'),
      children: [
        // =============================================================================
        // PAGES
        // =============================================================================
        {
          path: '/welcome',
          name: 'page-welcome',
          component: () => import('@/views/pages/welcome.vue'),
          meta: {
            rule: 'editor',
          },
        },
        {
          path: '/thanksforsigningup',
          name: 'page-thanksforsigningup',
          component: () => import('@/views/pages/thanksforsigningup.vue'),
          meta: {
            rule: 'editor',
          },
        },
        {
          path: '/create-payment',
          name: 'page-payment',
          component: () => import('@/views/pages/CreatePayment.vue'),
          meta: {
            rule: 'editor',
          },
        },
        {
          path: '/new-user',
          name: 'new-user',
          component: () => import('@/views/pages/newuser.vue'),
          meta: {
            rule: 'editor',
          },
        },
        {
          path: '/login',
          name: 'page-login',
          component: () => import('@/views/pages/login/Login.vue'),
          meta: {
            rule: 'editor',
          },
        },
        {
          path: '/selling_partner/login',
          name: 'page-login',
          component: () => import('./views/components/linkAccount/main.vue'),
          meta: {
            rule: 'editor',
          },
        },
        {
          path: '/selling_partner/authorize',
          name: 'dashboard',
          component: () => import('./views/components/linkAccount/main.vue'),
          meta: {
            rule: 'editor',
          },
        },
        {
          path: '/forgot-password',
          name: 'page-forgot-password',
          component: () =>
            import('@/views/pages/forgot-password/ForgotPassword.vue'),
          meta: {
            rule: 'editor',
          },
        },
        {
          path: '/reset-password',
          name: 'page-reset-password',
          component: () => import('@/views/pages/ResetPassword.vue'),
          meta: {
            rule: 'editor',
          },
        },
        {
          path: '/pages/error-404',
          name: 'page-error-404',
          component: () => import('@/views/pages/Error404.vue'),
          meta: {
            rule: 'editor',
          },
        },
        {
          path: '/pages/error-500',
          name: 'page-error-500',
          component: () => import('@/views/pages/Error500.vue'),
          meta: {
            rule: 'editor',
          },
        },
        {
          path: '/pages/not-authorized',
          name: 'page-not-authorized',
          component: () => import('@/views/pages/NotAuthorized.vue'),
          meta: {
            rule: 'editor',
          },
        },
        {
          path: '/preparing',
          name: 'preparing',
          component: () => import('@/views/pages/preparing'),
          meta: {
            rule: 'editor',
          },
        },
        {
          path: '/link-account',
          name: 'link-account',
          component: () => import('@/views/components/linkAccount/main.vue'),
          meta: {
            rule: 'editor',
          },
        },
      ],
    },
    // Redirect to 404 page, if no match found
    {
      path: '*',
      redirect: '/pages/error-404',
    },
  ],
});
/* eslint-disable */
router.afterEach((to) => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg');
  if (appLoading) {
    appLoading.style.display = 'none';
  }
});

router.beforeEach(async (to, from, next) => {
  let storeInstance = await Store();
  let apiToken = localStorage.getItem('token');
  var store = to.query.store || localStorage.getItem('store');
  var marketplace = localStorage.getItem('marketplace');
  let userInfo =
    storeInstance.getters['userInfo/uInfo'] ||
    (await localForage.getItem('userInfo'));
  const email = localStorage.getItem('email');
  const selectedStoreInState = storeInstance.getters.selectedStore;

  let storeArray = [];
  var isTrial;
  if (userInfo && userInfo.user) {
    storeArray = userInfo.user.store;
  }
  let screenPermissionList = [];

  const selectedStore = storeArray.find(
    (item) => item.storeId === store && item.marketplaceName === marketplace
  );

  if (storeArray && storeArray.length > 0) {
    for (let i in storeArray) {
      if (
        store === storeArray[i].storeId &&
        marketplace === storeArray[i].marketplaceName
      ) {
        for (let j in storeArray[i].screenPermissionList) {
          screenPermissionList.push(
            storeArray[i].screenPermissionList[j].PermissionCode
          );
        }
      }
    }
  }

  let firstScreenPathArrays = [];

  for (let i in screenPermissionListJson) {
    for (let j in screenPermissionList) {
      if (
        screenPermissionList[j].includes(
          screenPermissionListJson[i].permissionName
        )
      ) {
        firstScreenPathArrays.push(screenPermissionListJson[i].path);
      }
    }
  }
  let firstScreenPath = '/';
  if (firstScreenPathArrays.length > 0) {
    firstScreenPath = firstScreenPathArrays[0];
  } else if (selectedStore?.isVendor) {
    firstScreenPath = '/vendor-dashboard';
  } else if (selectedStore?.isWalmart) {
    firstScreenPath = '/walmart-dashboard';
  }

  if (
    selectedStore &&
    !selectedStore?.isVendor &&
    !selectedStore?.isWalmart &&
    (to.path !== from.path || to.path === '/') &&
    to.path !== '/login' &&
    to.path !== '/success-payment' &&
    to.query?.state !== 'from_no_permission' &&
    to.path !== '/error-payment' &&
    to.path !== '/selling_partner/authorize' &&
    to.path !== '/selling_partner/login' &&
    to.path !== '/link-account' &&
    !to.path.startsWith('/admin-dashboard') &&
    !from.path.startsWith('/admin-dashboard') &&
    !to.path.startsWith('/vendor') &&
    !to.path.startsWith('/walmart')
  ) {
    isTrial = await getTrialTime();
    const isSubstringPresent = firstScreenPathArrays.some((item) =>
      to.path.includes(item)
    );
    if (!isTrial) {
      if (
        to.path === firstScreenPath ||
        !firstScreenPathArrays.length ||
        firstScreenPathArrays.includes(to.path) ||
        isSubstringPresent
      ) {
        return next();
      }
      return router.push({ path: firstScreenPath });
    }

    if (
      !storeInstance.getters['userInfo/uInfo'].user.userPermissionList.find(
        (x) => x.PermissionCode === 'advertising_admin_user'
      ) &&
      isTrial?.repricerStatus?.toLowerCase() === 'paid' &&
      to.path === '/advertising-audit-reports' &&
      selectedStore?.selectedForPPC
    ) {
      return router.push({ path: '/advertising' });
    }

    let hasPermission = true;
    let hasOnlyReimbursementPermission = false;

    const currentPackageInvisibleRoutes =
      storeInstance.getters[
        'packagesAndOrderForm/getCurrentPackageInvisibleRoutes'
      ];

    const newReimbursementPackageType =
      userInfo?.user?.packages?.find(
        (item) => item.isAdditionalPackage && !item.isVendorPackage
      )?.packageType || null;

    if (
      isTrial?.repricerStatus.toLowerCase() !== 'paid' &&
      isTrial?.trialMinutes > 0
    ) {
      if (
        to.path === firstScreenPath ||
        !firstScreenPathArrays.length ||
        firstScreenPathArrays.includes(to.path) ||
        isSubstringPresent
      ) {
        return next();
      }
      return router.push({ path: firstScreenPath });
    }
    if (
      (isTrial?.pricingStatus === 7 ||
        isTrial?.pricingStatus === newReimbursementPackageType) &&
      !selectedStore?.isVendor &&
      !selectedStore?.isWalmart &&
      to.path !== '/login' &&
      to.path !== '/link-account' &&
      to.path !== '/search-product' &&
      to.path !== '/settings' &&
      to.path !== '/new-user' &&
      to.path !== '/preparing' &&
      to.path !== '/reimbursement-analytics' &&
      to.path !== '/remeasurement-request'
    ) {
      hasPermission =
        to.path === '/pricing-management' &&
        (isTrial?.pricingStatus === newReimbursementPackageType ||
          isTrial?.pricingStatus === 7);

      hasOnlyReimbursementPermission = true;
    }

    if (
      isTrial?.repricerStatus?.toLowerCase() !== 'paid' &&
      isTrial?.trialMinutes <= 0 &&
      isTrial?.pricingStatus != 7 &&
      isTrial?.pricingStatus != newReimbursementPackageType &&
      !selectedStore?.isVendor &&
      !selectedStore?.isWalmart &&
      to.path !== '/link-account' &&
      to.path !== '/login' &&
      to.path !== '/settings' &&
      to.path !== '/search-product' &&
      to.path !== '/new-user' &&
      to.path !== '/preparing'
    ) {
      hasPermission = false;
    }

    if (currentPackageInvisibleRoutes.includes(to.path)) {
      hasPermission = false;
    }

    if (hasOnlyReimbursementPermission && from.path === '/login') {
      return router.push({ path: '/reimbursement-analytics' });
    }

    if (to.path === '/no-permissions') {
      if (hasPermission) {
        storeInstance.dispatch('auth/setAuthorizedPageHistory', to.path);
      } else {
        storeInstance.dispatch('auth/setDesiredRoute', to.meta);
        return next();
      }
    }
    if (
      !hasPermission &&
      !selectedStore?.isVendor &&
      !selectedStore.isWalmart &&
      isTrial.trialMinutes <= 0 &&
      ![
        '/advertising-audit-reports',
        '/advertising',
        '/advertising-analytics',
        '/advertising-ai-settings',
        '/advertising-ai-decisions',
        '/advertising-reports',
        '/advertising-search-term',
        '/advertisingPerformanceTrends',
      ].includes(to.path)
    ) {
      storeInstance.dispatch('auth/setDesiredRoute', to.meta);
      return router.push({ path: '/no-permissions' });
    }

    storeInstance.dispatch('auth/setAuthorizedPageHistory', to.path);
  }

  const isAdvertisingAdmin = await isCurrentUserAdvertisingAdmin();
  if (
    isAdvertisingAdmin === false &&
    ['/advertising-ai-settings'].includes(to.path)
  ) {
    return router.push({ path: '/' });
  }
  if (!userInfo && apiToken && email) {
    const urlDetail = 'user/user-information';
    try {
      const { data } = await api.bearer.post({ email }, urlDetail);
      const userInfo = data && (await data.Data);
      await storeInstance.dispatch('userInfo/loadInfo', userInfo);
      window.location.reload();
    } catch (e) {
      localStorage.clear();
      await localForage.clear();
      router.push('/login');
    }
  }

  if (apiToken && to.path === '/link-account') {
    const isRenewToken = Boolean(localStorage.getItem('renew_token'));
    if (userInfo.user.accountStatus === 0 || isRenewToken) return next();
    return router.push({ path: firstScreenPath });
  }
  // comment if want to display preparing page
  if (
    to.path === '/welcome' ||
    to.path === '/thanksforsigningup' ||
    to.path === '/login' ||
    to.path === '/new-user' ||
    to.path === '/forgot-password' ||
    to.path === '/reset-password' ||
    to.path === '/create-payment' ||
    to.path === '/pages/error-404' ||
    to.path === '/pages/error-500' ||
    to.path === '/callback' ||
    to.path === '/selling_partner/authorize' ||
    to.path === '/selling_partner/login'
  ) {
    if (to.path === '/selling_partner/login') {
      window.localStorage.setItem('sellerCentralConsent', JSON.stringify(to));
      window.localStorage.setItem('vendorCentralPathes', JSON.stringify(to));
      storeInstance.dispatch(
        'linkAccount/setRegionInfo',
        JSON.parse(window.localStorage.getItem('linkAccountSelectRegion'))
      );
      storeInstance.dispatch(
        'linkAccount/setSelectedMarket',
        JSON.parse(window.localStorage.getItem('linkAccountSelectMarket'))
      );
      storeInstance.dispatch(
        'linkAccount/setSelectedMarketType',
        JSON.parse(window.localStorage.getItem('linkAccountSelectMarketType'))
      );
      storeInstance.dispatch(
        'linkAccount/setUserInfo',
        JSON.parse(window.localStorage.getItem('linkAccountUserInfo'))
      );
      storeInstance.dispatch('updateTheme', 'light');
      return router.push({ path: '/link-account', query: { step: -1 } });
    }
    if (to.path === '/selling_partner/authorize') {
      let state = to.query.state.split(':');
      if (state?.[0] === 'link_vendor') {
        window.localStorage.setItem('vendorCentralPathes', JSON.stringify(to));
        storeInstance.dispatch(
          'linkAccount/setRegionInfo',
          JSON.parse(window.localStorage.getItem('linkAccountSelectRegion'))
        );
        storeInstance.dispatch(
          'linkAccount/setSelectedMarket',
          JSON.parse(window.localStorage.getItem('linkAccountSelectMarket'))
        );
        storeInstance.dispatch(
          'linkAccount/setUserInfo',
          JSON.parse(window.localStorage.getItem('linkAccountUserInfo'))
        );
        storeInstance.dispatch('updateTheme', 'light');
        return router.push({ path: '/link-account', query: { step: 5 } });
      } else if (state?.[0] === 'renew_vendor_token') {
        window.localStorage.setItem('vendorRenewTokenPath', JSON.stringify(to));
        return router.push({
          path: firstScreenPath,
          query: {
            store: localStorage.getItem('store'),
            marketplace: localStorage.getItem('marketplace'),
            state: 'renew',
          },
        });
      } else {
        if (state?.[0] === 'renew_token') {
          localStorage.setItem('renew_token', true);
        }
        window.localStorage.setItem('vendorCentralPathes', JSON.stringify(to));
        storeInstance.dispatch(
          'linkAccount/setRegionInfo',
          JSON.parse(window.localStorage.getItem('linkAccountSelectRegion'))
        );
        storeInstance.dispatch(
          'linkAccount/setSelectedMarket',
          JSON.parse(window.localStorage.getItem('linkAccountSelectMarket'))
        );
        storeInstance.dispatch(
          'linkAccount/setSelectedMarketType',
          JSON.parse(window.localStorage.getItem('linkAccountSelectMarketType'))
        );
        storeInstance.dispatch(
          'linkAccount/setUserInfo',
          JSON.parse(window.localStorage.getItem('linkAccountUserInfo'))
        );
        window.localStorage.setItem('sellerCentralPathes', JSON.stringify(to));
        storeInstance.dispatch('updateTheme', 'light');
        return router.push({ path: '/link-account', query: { step: 6 } });
      }
    }
    if (apiToken && to.path != '/new-user' && to.path != '/create-payment') {
      return router.push({ path: firstScreenPath });
    }
    return next();
  } else {
    if (
      screenPermissionList.length > 0 &&
      !screenPermissionList.includes(to.meta.permissionName) &&
      to.path !== '/no-permissions'
    ) {
      return router.push({ path: firstScreenPath });
    }
  }
  if (to.path === '/preparing') {
    if (apiToken == null || apiToken == undefined || apiToken == '') {
      return router.push({ path: '/login' });
    } else {
      return router.push({ path: firstScreenPath });
    }
  } else {
    //Check token exists
    if (apiToken == null || apiToken == undefined || apiToken == '') {
      return router.push({ path: '/login' });
    }
  }
  // uncomment if want to display preparing page
  // apiToken = null;
  // if (to.path === '/preparing') {
  //   if (apiToken && apiToken !== undefined && apiToken !== '') {
  //     return router.push({ path: '/' });
  //   }
  //   return next();
  // } else {
  //   //Check token exists
  //   if (apiToken == null || apiToken == undefined || apiToken == '') {
  //     // return router.push({ path: '/login' })
  //     return router.push({ path: '/preparing' });
  //   }
  // }

  // If auth required, check login. If login fails redirect to login page
  // if (to.meta.authRequired) {
  //   if (!(auth.isAuthenticated())) {
  //     router.push({ path: '/pages/login', query: { to: to.path } })
  //   }
  // }
  if (
    selectedStore?.isVendor &&
    selectedStore?.hasVendorRecoveryService &&
    to.path === '/no-permissions'
  ) {
    return router.push({ path: '/vendor-dashboard' });
  }
  if (
    selectedStore?.isVendor &&
    !selectedStore?.hasVendorRecoveryService &&
    (to.path === '/vendor-check-credentials' ||
      to.path === '/vendor-check-credentials-eva')
  ) {
    return router.push({ path: '/no-permissions' });
  }
  if (
    selectedStore?.isVendor &&
    selectedStore?.hasVendorRecoveryService &&
    selectedStore?.hasOtpSecretKey &&
    (to.path === '/vendor-check-credentials' ||
      to.path === '/vendor-check-credentials-eva' ||
      to.path === '/no-permissions')
  ) {
    return router.push({ path: '/vendor-reimbursement-analytics' });
  }
  if (
    selectedStore?.isVendor &&
    [
      '/vendor-invoice',
      '/vendor-reimbursement-analytics',
      '/vendor-disputes',
    ].includes(to.path)
  ) {
    if (!selectedStore?.hasVendorRecoveryService) {
      storeInstance.dispatch('auth/setDesiredRoute', to.meta);
      return router.push({ path: '/no-permissions' });
    }
    if (!selectedStore?.hasOtpSecretKey) {
      return email?.endsWith('@eva.guru')
        ? router.push({ path: '/vendor-check-credentials-eva' })
        : router.push({ path: '/vendor-check-credentials' });
    }
  }

  /**
   * EVA 3PL Page Middleware
   */
  if (to.path === '/eva-3pl-inventory') {
    if (selectedStore) {
      if (!selectedStore.is3plStore) {
        return router.push({ path: '/' });
      }
    }
  }

  /**
   * Receive Orders Page Middleware
   */
  if (to.path === '/receive-orders') {
    if (selectedStore) {
      if (!selectedStore.is3plStore) {
        return router.push({ path: '/' });
      }
    }
  }

  /**
   * New Receive Orders Page Middleware
   */
  if (to.path === '/new-receive-orders') {
    if (selectedStore) {
      if (!selectedStore.is3plStore) {
        return router.push({ path: '/' });
      }
    }
  }

  /**
   * Integrations Shopify Page Middleware
   */
  if (to.name === 'integration') {
    if (!selectedStore) return router.push({ path: '/' });
    const marketplace = to.params.marketplace;
    to.meta.breadcrumb[to.meta.breadcrumb.length - 1].title =
      titleCase(marketplace);
    if (!['shopify', 'walmart', 'shipstation'].includes(marketplace))
      return router.push({ path: '/integrations' });
  }

  /**
   * SKU Performance Page Middleware
   */
  if (to.path === '/SKU-performance-details') {
    if (!selectedStore) return router.push({ path: '/' });

    if (!to.query.sku || !to.query.asin)
      return router.push({ path: '/SKU-performance' });
  }

  const userPermissionList =
    storeInstance.getters['userInfo/uInfo'].user.userPermissionList;
  let hasCRMPermission = userPermissionList.find((item) => {
    return adminDashboardOnlyAccountPermissions.includes(item.PermissionCode);
  });

  const currentUserPermissionCode =
    storeInstance.getters['userInfo/currentUserPermissionCode'];

  if (
    [
      'admin-dashboard',
      'admin-dashboard/pages',
      'admin-dashboard/details',
      'admin-dashboard/details/infoDetails',
    ].includes(to.name)
  ) {
    if (!hasCRMPermission) {
      return router.push({ path: '/' });
    }
  }

  if (to.name === 'admin-dashboard') {
    if (currentUserPermissionCode === 'research_and_development_user') {
      return router.push({ path: '/admin-dashboard/store-list' });
    }

    return router.push({ path: '/admin-dashboard/account-list' });
  }

  if (to.name === 'admin-dashboard/pages') {
    if (to.params.page === 'mrr-list') {
      to.meta.pageTitle = 'Monthly Recurring Revenue (MRR)';
    } else if (to.params.page === 'manage-stores') {
      to.meta.pageTitle = 'Your Eva Stores';
    } else {
      to.meta.pageTitle = kebabCaseToTitleCase(to.params.page);
    }

    if (to.params.page === 'account-list') {
      if (currentUserPermissionCode === 'research_and_development_user') {
        return router.push({ path: '/admin-dashboard/store-list' });
      }
    }
  }

  if (to.name === 'admin-dashboard/details') {
    const isAddNewContact =
      storeInstance.getters['adminPanel/getIsAddNewContact'];
    if (
      from.name === 'admin-dashboard/details/infoDetails' ||
      to.meta.breadcrumb.length > 4
    ) {
      to.meta.breadcrumb.splice(4, 1);
      if (isAddNewContact) {
        storeInstance.dispatch('adminPanel/setIsAddNewContact', false);
      }
    } else {
      to.meta.pageTitle = `${kebabCaseToTitleCase(to.params.tableName)} Detail`;
      to.meta.breadcrumb[2].title = `${titleCase(to.params.tableName)} List`;
      to.meta.breadcrumb[2].url = `/admin-dashboard/${to.params.tableName}-list`;
      to.meta.breadcrumb[to.meta.breadcrumb.length - 1].title = `${titleCase(
        to.params.tableName
      )} Detail`;
    }

    //create contact
    if (to.params.accountId == 'createContact') {
      storeInstance.dispatch('adminPanel/setIsAddNewContact', true);
      to.meta.breadcrumb[to.meta.breadcrumb.length - 1].title =
        titleCase('Create Contact');
    }

    if (to.params.accountId == 'createOpportunity') {
      storeInstance.dispatch('adminPanel/setIsAddNewOpportunity', true);
      to.meta.breadcrumb[to.meta.breadcrumb.length - 1].title =
        titleCase('Create Opportunity');
    }

    const isAddNewAccount =
      storeInstance.getters['adminPanel/getIsAddNewAccount'];

    if (isAddNewAccount || to.params.accountId == 'createAccount') {
      storeInstance.dispatch('adminPanel/setIsAddNewAccount', true);
      to.meta.breadcrumb[to.meta.breadcrumb.length - 1].title =
        titleCase('Create Account');
      to.meta.pageTitle = titleCase('Create Account');
    }
  }

  if (to.name == 'admin-dashboard/details/infoDetails') {
    if (from.name === 'admin-dashboard/details') {
      const isAddNewContact =
        storeInstance.getters['adminPanel/getIsAddNewContact'];
      const isAddNewOpportunity =
        storeInstance.getters['adminPanel/getIsAddNewOpportunity'];
      const previousBreadcrumb = from.meta.breadcrumb;

      if (isAddNewContact) {
        previousBreadcrumb.push({
          title: titleCase(to.params.secondTableName),
          active: true,
        });
        to.meta.breadcrumb = previousBreadcrumb.map((item, index) =>
          index == previousBreadcrumb.length - 2
            ? { ...item, active: false, url: from.path }
            : item
        );
      } else if (isAddNewOpportunity) {
        previousBreadcrumb.push({
          title: titleCase(to.params.secondTableName),
          active: true,
        });
        to.meta.breadcrumb = previousBreadcrumb.map((item, index) =>
          index == previousBreadcrumb.length - 2
            ? { ...item, active: false, url: from.path }
            : item
        );
      } else {
        previousBreadcrumb.push({
          title: titleCase(to.params.secondTableName),
          active: true,
        });

        to.meta.breadcrumb = previousBreadcrumb.map((item, index) =>
          index == previousBreadcrumb.length - 2
            ? { ...item, active: false, url: from.path }
            : item
        );
      }
    } else {
      to.meta.breadcrumb = to.meta.breadcrumb.map((item, index) => {
        if (index == to.meta.breadcrumb.length - 3) {
          item = {
            active: false,
            title: `${titleCase(to.params.tableName)} List` || item.title,
            url: `/admin-dashboard/${to.params.tableName}-list`,
          };
        }
        if (index == to.meta.breadcrumb.length - 2) {
          item = {
            active: false,
            title: `${titleCase(to.params.tableName)} Detail` || item.title,
            url: `/admin-dashboard/${to.params.tableName}-list/${to.params.tableName}/${to.params.accountId}`,
          };
        }
        if (index == to.meta.breadcrumb.length - 1) {
          item = {
            active: true,
            title:
              `${titleCase(to.params.secondTableName)} Detail` || item.title,
          };
        }
        return item;
      });
      to.meta.pageTitle = `${kebabCaseToTitleCase(
        to.params.secondTableName
      )} Detail`;
    }
  }
  if (to.path === '/advertising' && to.query?.scope && to.query?.code) {
    storeInstance.dispatch(
      'linkAccount/setRegionInfo',
      JSON.parse(window.localStorage.getItem('linkAccountSelectRegion'))
    );
    storeInstance.dispatch(
      'linkAccount/setSelectedMarket',
      JSON.parse(window.localStorage.getItem('linkAccountSelectMarket'))
    );
    storeInstance.dispatch(
      'linkAccount/setConnectedStore',
      JSON.parse(window.localStorage.getItem('linkAccountConnectedStore'))
    );
    storeInstance.dispatch(
      'linkAccount/setUserInfo',
      JSON.parse(window.localStorage.getItem('linkAccountUserInfo'))
    );
    return router.push({
      path: '/link-account',
      query: { ...to.query, step: 7 },
    });
  }
  if (!selectedStore?.isVendor && to.path === '/integrations') return next();
  if (selectedStore?.isVendor && to.path === '/success-payment') return next();
  if (to.path === '/settings') return next();
  if (to.path === '/search-product') return next();

  if (
    selectedStore?.isVendor &&
    !to.path.startsWith('/vendor') &&
    !['/advertising'].includes(to.path) &&
    !to.path.startsWith('/admin-dashboard') &&
    !to.path.startsWith('/no-permission')
  ) {
    return router.push({
      path: '/vendor-dashboard',
    });
  }
  if (
    selectedStore?.isWalmart &&
    !to.path.startsWith('/walmart') &&
    !to.path.startsWith('/admin-dashboard')
  ) {
    return router.push({ path: '/walmart-dashboard' });
  }
  if (
    (!selectedStore?.isVendor && to.path.startsWith('/vendor')) ||
    (!selectedStore?.isWalmart && to.path.startsWith('/walmart'))
  ) {
    return router.push({ path: '/' });
  }

  if (
    hasCRMPermission &&
    !selectedStoreInState &&
    !to.path.startsWith('/admin-dashboard')
  ) {
    return router.push({ path: '/admin-dashboard' });
  }

  if (to.name === 'advertisingAnalytics') {
    let sortingKeys = {
      sales: {
        key: 'sales',
        field: 'SALES',
        name: 'Sales',
        priority: 11,
      },
      orders: {
        key: 'orders',
        field: 'ORDERS',
        name: 'Orders',
        priority: 17,
      },
      impressions: {
        key: 'impressions',
        field: 'IMPRESSIONS',
        name: 'Impressions',
        priority: 15,
      },
      clicks: {
        key: 'clicks',
        field: 'CLICKS',
        name: 'Clicks',
        priority: 16,
      },
    };
    if (from.query?.activeTab) {
      storeInstance.dispatch(
        'advertising/changeActiveTab',
        from.query.activeTab
      );
      window.localStorage.setItem('activeTab', from.query.activeTab);
      if (from.query?.smartFilterId) {
        storeInstance.dispatch('advertising/setSelectedAnalyticsSmartFilters', {
          analyticType: from.query.activeTab,
          smartFilterId: Number(from.query.smartFilterId),
        });
      }
      if (from.query?.sortingKey) {
        storeInstance.dispatch('advertising/changeSort', {
          'query.sortingKey': from.query.sortingKey,
          'query.sortingType': from.query.sortingType,
        });

        if (from.query?.sortingType === 'asc') {
          storeInstance.dispatch('advertising/changeFilter', {
            'query.filters': [
              {
                field: sortingKeys[from.query?.sortingKey].field,
                name: sortingKeys[from.query?.sortingKey].name,
                priority: sortingKeys[from.query?.sortingKey].priority,
                operator: 'GREATER_THAN_OR_EQUAL',
                values: ['1'],
              },
            ],
          });
        }
      }
    }

    let currentQuery = { ...to.query, ...from.query };

    return next({
      query: currentQuery,
    });
  } else {
    next();
  }
  return next();
  // Specify the current path as the customState parameter, meaning it
  // will be returned to the application after auth
  // auth.login({ target: to.path });
});

export default router;

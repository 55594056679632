export default {
  terms: {
    clear: 'Clear',
    type: 'Type',
    button: 'Button',
    field: 'Field',
    link: 'Link',
    connect: 'Connect',
    save: 'Save',
    saving: 'Saving...',
    confirm: 'Confirm',
    cancel: 'Cancel',
    finish: 'Finish',
    import: 'Import',
    export: 'Export',
    exporting: 'Exporting',
    compare: 'Compare',
    back: 'Back',
    sync: 'Sync',
    all: 'All',
    failed: 'Failed',
    updated: 'Updated',
    update: 'Update',
    download: 'Download',
    file: 'File',
    files: 'Files',
    file_name: 'File name',
    option: 'Option',
    syncing: 'Syncing',
    quantity: 'Quantity',
    see_details: 'See Details',
    see_overview: 'See Overview',
    congratulations: 'Congratulations',
    pre_approved_for: 'Pre-Approved for',
    more_than: (ctx) => `more than ${ctx.named('moreThan')}`,
    less_than: (ctx) => `less than ${ctx.named('lessThan')}`,
    add: (ctx) => `Add ${ctx.named('field') ? ctx.named('field') : ''}`,
    please_check: 'Please check',
    submit: 'Submit',
    save_changes: 'Save Changes',
    search: 'Search',
    search_placeholder:
      'Enter product name, phrase or identifiers (ASIN, ISBN, GCID, UPC, EAN, JAN)',
    copy: (ctx) => `Copy ${ctx.named('field')}`,
    copied: (ctx) => `Copied ${ctx.named('field')}`,
    currency: 'Currency',
    currency_rate: 'Currency Rate',
    choose: 'Choose',
    aggregated: 'Aggregated',
    alert: 'Alert',
    level: 'Level',
    negative: 'Negative',
    status: 'Status',
    full: 'Full',
    out: 'Out',
    in: 'In',
    win: 'Win',
    sharing: 'Sharing',
    chasing: 'Chasing',
    match: 'Match',
    suppression: 'Suppression',
    with: 'With',
    without: 'Without',
    select: 'Select',
    data: 'Data',
    settings: 'Settings',
    options: 'Options',
    loading: 'Loading',
    value: 'Value',
    day: 'Day',
    days: 'Days',
    hours: 'Hours',
    set: 'Set',
    set_field: (ctx) =>
      `${ctx.linked('general.terms.set')} ${ctx.named('field')}`,
    percentile: 'Percentile',
    potential: 'Potential',
    unit: (ctx) => `${ctx.named('isPlural') ? 'Units' : 'Unit'}`,
    oversize_pallet: 'Oversize Pallet',
    pallet: 'Pallet',
    box: 'Box',
    number: 'Number',
    no_data_available_yet: 'No data available yet.',
    available: 'Available',
    history: 'History',
    report: 'Report',
    please_wait: 'Please wait while gathering your data.',
    error: 'Error',
    done: 'Done',
    in_progress: 'In progress',
    id: 'ID',
    enabled: 'Enabled',
    disabled: 'Disabled',
    order: 'Order',
    order_form: '@:general.terms.order Form',
    next: 'Next',
    ok: 'OK',
    request: 'Request',
    response: 'Response',
    upload: 'Upload',
    uploading: 'Uploading',
    process: 'Process',
    processing: 'Processing',
    template: 'Template',
    result: 'Result',
    results: 'Results',
    contact_us: 'Contact Us',
    first_digit: 'First Digit',
    last_digit: 'Last Digit',
    please: 'Please',
    total_quantity: 'Total quantity',
    save_new: 'Save & New',
    delete: 'Delete',
    success: 'Success',
    yes: 'Yes',
    no: 'No',
    view: 'View',
    edit: 'Edit',
    add_new: 'Add & New',
    filtered_report: 'Filtered Report',
    product: 'Product',
  },
  terminology: {
    store_name: 'Store name',
    store: 'Store',
    stores: 'Stores',
    account: 'Account',
    marketplace: 'Marketplace',
    region: 'Region',
    seller_central: 'Seller Central',
    seller_account: 'Seller Account',
    advertising: 'Advertising',
    timezone: 'Time zone',
    store_type: 'Store type',
    amazon_seller_account: 'Amazon @:general.terminology.seller_account',
    amazon_seller_central: 'Amazon @:general.terminology.seller_central',
    claim_your_loan: 'Claim Your Loan',
    get_funding_now: 'Get Funding Now',
    asin: 'ASIN',
    sku: 'SKU',
    fnsku: 'FNSKU',
    skus: 'SKUs',
    name: 'Name',
    sales: 'Sales',
    velocity: 'Velocity',
    price: 'Price',
    prices: 'Prices',
    pricer: 'Pricer',
    repricer: 'Repricer',
    min_price: 'Min. Price',
    max_price: 'Max. Price',
    profit: 'Profit',
    margin: 'Margin',
    roi: 'ROI',
    amount: 'Amount',
    refund: 'Refund',
    referral_fee: 'Referral Fee',
    fba_fee: 'FBA Fee',
    fba_storage_fee: 'FBA Storage Fee',
    vat: 'VAT',
    advertising_cost: 'Advertising Cost',
    variable_closing_fee: 'Variable Closing Fee',
    sku_refund_rate: 'SKU Refund Rate',
    product_name: 'Product Name',
    product_cost: 'Product Cost',
    avg: 'Avg.',
    sales_per_units: 'Sales / Units',
    units_per_day: 'Units / Day',
    cost_per_quantity: 'Cost / Quantity',
    avg_selling_price:
      '@:general.terminology.avg @:general.terminology.sales @:general.terminology.price',
    avg_roi: '@:general.terminology.avg @:(general.terminology.roi) %',
    min_roi: 'Min @:(general.terminology.roi) %',
    avg_margin: '@:general.terminology.avg @:(general.terminology.margin) %',
    min_margin: 'Min @:(general.terminology.margin) %',
    pricing: 'Pricing',
    advanced_settings: 'Advanced Settings',
    pricing_settings: 'Pricing Settings',
    fba: 'FBA',
    fbm: 'FBM',
    total: 'Total',
    login: 'Login',
    height: 'Height',
    width: 'Width',
    length: 'Length',
    weight: 'Weight',
    parent_asin: 'Parent ASIN',
    cogs: 'COGS',
    offer: 'Offer',
    reimbursement: 'Reimbursement',
    inventory: 'Inventory',
    expense: 'Expense',
    other_expenses: 'Other Expenses',
    visit_page: (ctx) => `Visit ${ctx.named('page')} Page`,
    inbound: 'Inbound',
    total_field: (ctx) =>
      `${ctx.linked('general.terminology.total')} ${ctx.named('field')}`,
    use_field: (ctx) => `Use ${ctx.named('field')?.toLowerCase()}`,
    cost: 'Cost',
    buy_box: 'Buy Box',
    replenishment: 'Replenishment',
    stock: 'Stock',
    merchant: 'Merchant',
    with_cost: '@:general.terms.with @:general.terminology.cost',
    without_cost: '@:general.terms.without @:general.terminology.cost',
    supply: 'Supply',
    fc_transfer: 'FC Transfer',
    request_time: 'Request Time',
    complete_time: 'Complete Time',
    template_id: '@:general.terms.template @:general.terms.id',
    warehouse: 'Warehouse',
    vendor: 'Vendor',
    comments: 'Comments',
    lpn: 'LPN',
    upc: 'UPC',
    base_sku: 'Base SKU',
    ean: 'EAN',
    other_code: 'Other Code',
    brand: 'Brand',
    ordered_qty: 'Ordered Qty',
    received_qty: 'Received Qty',
    other: 'Other',
    invoice_number: 'Invoice Number',
    vendor_po_code: 'Vendor PO Code',
    tracking_id: 'TrackingId',
    tracking_id_title: 'Tracking ID',
    product_not_found: 'Product not found',
    searching: 'Searching',
    eva_code: 'Eva Code',
    your_comments: 'Your Comments',
    location: 'Location',
    inventory_on_hand: 'Inventory on Hand',
    new: 'New',
    like_new: 'Like New',
    damaged: 'Damaged',
    expired: 'Expired',
    returned: 'Returned',
  },
  user: {
    user: 'User',
    email: 'Email',
    password: 'Password',
    phone_number: 'Phone number',
    first_name: 'First name',
    last_name: 'Last name',
  },
  regions: {
    na: 'North America',
    eu: 'Europe',
    fe: 'Far East',
  },
  store_types: {
    brand_or_private_label: 'Brand/Private Label',
    reseller: 'Reseller',
    vendor: 'Vendor',
  },
  validation: {
    min: 'Min',
    max: 'Max',
    valid: 'Valid',
    invalid: 'Invalid',
    required: 'Required',
    answer_question: 'Please answer the question',
    higher_than: 'Higher than',
    lower_than: 'Lower than',
    n_characters: (ctx) => `${ctx.named('n')} characters`,
    n_digits: (ctx) => `${ctx.named('n')} digits`,
    n_letters: (ctx) => `${ctx.named('n')} letters`,
    n_words: (ctx) => `${ctx.named('n')} words`,
    field_is: (ctx) =>
      `${ctx.named('field')} is ${ctx.named('is')?.toLowerCase()}`,
    field_must_be: (ctx) => {
      let stmt = `${ctx.named('mustBe')?.toLowerCase()}`;
      if (ctx.named('atLeast')) stmt = `at least ${ctx.named('atLeast')}`;
      else if (ctx.named('lessThan'))
        stmt = `less than ${ctx.named('lessThan')}`;
      return `${ctx.named('field')} must be ${stmt}`;
    },
  },
  hints: {
    select: (ctx) => `Select ${ctx.named('field')?.toLowerCase()}`,
    select_your: (ctx) => `Select your ${ctx.named('field')?.toLowerCase()}`,
    type_characters: (ctx) =>
      `Please type ${ctx.named('n')} or more characters.`,
    preview_field: (ctx) => `Preview ${ctx.named('field')?.toLowerCase()}`,
    enter: (ctx) => `Enter ${ctx.named('field')}`,
  },
  errors: {
    500: 'Something went wrong. Please try again later.',
  },
  date: {
    date: 'Date',
    day: 'Day',
    week: 'Week',
    month: 'Month',
    year: 'Year',
    custom_date: 'Custom Date',
    today: 'Today',
    yesterday: 'Yesterday',
    tomorrow: 'Tomorrow',
    date_to_date: (ctx) =>
      `${ctx.named('start')} to ${ctx.named('end').toLowerCase()}`,
    last_n_days: (ctx) => `Last ${ctx.named('n')} days`,
    n_hours: (ctx) => `${ctx.named('n')} hours`,
    n_days: (ctx) => `${ctx.named('for') ? 'for' : ''} ${ctx.named('n')} days`,
    n_months: (ctx) => `${ctx.named('n')} months`,
    n_years: (ctx) => `${ctx.named('n')} years`,
    current: (ctx) => `Current ${ctx.named('field')?.toLowerCase()}`,
    days_of_inventory: 'Days of Inventory',
    last: (ctx) =>
      `Last ${ctx.named('updated_time') ? 'Updated Time:' : ''} ${ctx
        .named('field')
        ?.toLowerCase()}`,
    previous: (ctx) => `Previous ${ctx.named('field')?.toLowerCase()}`,
    exact_dates: 'Exact dates',
    start_date: 'Start Date',
    end_date: 'End Date',
    daily: 'Daily',
    weekly: 'Weekly',
    monthly: 'Monthly',
    custom: 'Custom',
  },
  charts: {
    drill_up_text: 'Back',
    no_data: 'Check your options please',
    reset_zoom: 'Reset',
    please_wait:
      '@:general.terms.please_wait Your charts will be ready shortly.',
  },
  filters: {
    filter_by: 'Filter by',
    search_for:
      'Search for @:general.terminology.asin, @:general.terminology.sku, @:general.terminology.fnsku, @:general.terminology.product_name',
    alert_level: '@:general.terms.alert @:general.terms.level',
    search_for_3pl: '@:general.filters.search_for, @:general.terminology.upc',
    start_typing: (ctx) =>
      `Start typing to search for a  ${ctx.named('field')}.`,
    fulfilled_by: 'Fulfilled by',
    negative_profit: '@:general.terms.negative @:general.terminology.profit',
    negative_margin: '@:general.terms.negative @:general.terminology.margin',
    negative_roi: '@:general.terms.negative @:general.terminology.roi',
    profit_status: '@:general.terminology.profit @:general.terms.status',
    cost_status: '@:general.terminology.cost @:general.terms.status',
    buy_box_status: '@:general.terminology.buy_box @:general.terms.status',
    full_replenishment:
      '@:general.terms.full @:general.terminology.replenishment',
    in_stock: '@:general.terms.in @:general.terminology.stock',
    stock_out: '@:general.terminology.stock @:general.terms.out',
    buy_box_field: (ctx) =>
      `${ctx.linked('general.terminology.buy_box')} ${ctx.named('field')}`,
    win_by_amazon: 'win by Amazon below min. price',
    min_max: 'Min/Max',
    price_below_min: 'price below min. price',
    win_with_min: 'win with min. price',
    win_with_max: 'win with max. price',
    chasing_with_min: 'chasing with min. price',
    chasing_with_max: 'chasing with max. price',
    child_based_parent_asin_pricing: 'Child-based Parent ASIN Pricing',
    inventory_protection: '@:general.terminology.inventory Protection',
    asin_relations: {
      parent: 'Parent',
      child: 'All-for-One Child Protection',
      master: 'Master',
      master_child: '@:general.filters.asin_relations.master & @:Child',
      child_individual: 'Child Individual',
    },
  },
  actions: {
    sync_from: (ctx) =>
      `${ctx.linked('general.terms.sync')} from ${ctx.named('source')}`,
    syncing_from: (ctx) =>
      `${ctx.linked('general.terms.syncing')} from ${ctx.named('source')}`,
    sync_field: (ctx) =>
      `${ctx.linked('general.terms.sync')} ${ctx.named('field')}`,
    syncing_field: (ctx) =>
      `${ctx.linked('general.terms.syncing')} ${ctx.named('field')}`,
    download_field: (ctx) =>
      `${ctx.linked('general.terms.download')} ${ctx.named('field')}`,
    download_field_as: (ctx) =>
      `${ctx.linked('general.terms.download')} ${ctx.named(
        'field'
      )} ${ctx.named('as')}`,
    upload_field: (ctx) =>
      `${ctx.linked('general.terms.upload')} ${ctx.named('field')}`,
    uploading_field: (ctx) =>
      `${ctx.linked('general.terms.uploading')} ${ctx.named('field')}`,
    upload_field_hint: (ctx) =>
      `Once your ${ctx
        .named('field')
        .toLowerCase()} is uploaded, you will receive an email for
      the report of your upload.`,
    update_field: (ctx) =>
      `${ctx.linked('general.terms.update')} ${ctx.named('field')}`,
    updated_field: (ctx) =>
      `${ctx.linked('general.terms.updated')} ${ctx.named('field')}`,
    failed_field: (ctx) =>
      `${ctx.linked('general.terms.failed')} ${ctx.named('field')}`,
    process_field: (ctx) =>
      `${ctx.linked('general.terms.process')} ${ctx.named('field')}`,
    processing_field: (ctx) =>
      `${ctx.linked('general.terms.processing')} ${ctx.named('field')}`,
    export_field: (ctx) =>
      `${ctx.linked('general.terms.export')} ${ctx.named('field')}`,
    import_field: (ctx) =>
      `${ctx.linked('general.terms.import')} ${ctx.named('field')}`,
    request_field: (ctx) =>
      `${ctx.linked('general.terms.request')} ${ctx.named('field')}`,
    field_as: (ctx) => `${ctx.named('field')} ${ctx.named('as')}`,
    field_template: (ctx) =>
      `${ctx.named('field')} ${ctx.linked('general.terms.template')}`,
    field_template_as: (ctx) =>
      `${ctx.named('field')} ${ctx.linked(
        'general.terms.template'
      )} ${ctx.named('as')}`,
    as_type: {
      excel: 'as Excel',
      csv: 'as CSV',
      filtered_report: 'as Filtered Report',
      filtered_history: 'as Filtered History',
    },
    template_request: {
      download: {
        title: '@:general.terms.template @:general.terms.request',
        hint: 'Preparing to @.lower:general.terms.download Parent ASIN @:general.terms.template file. This may take a while.',
      },
      upload: {
        title: '@:general.terms.template @:general.terms.request',
        hint: 'Preparing to @.lower:general.terms.upload Parent ASIN @:general.terms.template file. This may take a while.',
      },
      error: {
        hint: 'Something went wrong while obtaining Parent ASIN Template. Please try again later...',
      },
    },
    template_history: '@:general.terms.template @:general.terms.history',
  },
  routing: {
    go_to: (ctx) => `Go to ${ctx.named('page')}`,
  },
  prompt: {
    please_check: 'Please Check',
    unsaved_changes: 'You have unsaved changes. Do you want to save?',
    min_max_update:
      'Please keep in mind that All Min & Max prices for SKUs will be updated on Eva. Do you confirm?',
    renew_token: {
      title: 'Renew Token',
      hint: 'Please renew the authorization token from Amazon Seller Central.',
      confirm_text: 'Go to Seller Central',
      cancel_text: 'Not Now',
    },
    renew_token_vendor: {
      title: 'Renew Token',
      hint: 'Please renew the authorization token from Amazon Vendor Central.',
      confirm_text: 'Go to Vendor Central',
      cancel_text: 'Not Now',
    },
  },
  select: {
    please_select: 'Please Select',
  },
  repricer_status: {
    start_monthly_plan: 'Start Monthly Plan',
    start_monthly_plan_hint:
      'Start your Monthly Plan to fully use the advantages of Eva!',
    free_trial_end: 'Your free Eva.guru trial has ended',
    full_free_trial_end:
      "Your Eva free trial, where you can try Eva's all features ended.",
    free_trial_ends_in: 'Your free Eva.guru trial ends in',
    contact_cs_team: 'Have a question? Contact our Customer Success Team',
    finance_hint: 'Get the financing to grow your business!',
    upgrade_your_plan: 'Upgrade Your Plan',
    upgrade_hint: 'Upgrade your plan to fully use the advantages of Eva!',
  },
  footer: {
    copyright: `COPYRIGHT ©${new Date().getFullYear()} EVA, All Rights Reserved`,
  },
  abbreviations: {
    scheduled_pricing: {
      original: 'Scheduled Pricing',
      short: 'SP',
    },
    fixed_price: {
      original: 'Fixed Price',
      short: 'FP',
    },
    map_price: {
      original: 'MAP Price',
      short: 'MP',
    },
    price_rounding: {
      original_sku_level: 'SKU Level Price Rounding',
      original_store_level: 'Store Level Price Rounding',
      short: 'PR',
    },
    target_velocity_pricing: {
      original: 'SKU Level New Product Launch',
      short: 'NPL',
    },
    compete_only_fbm: {
      original_sku_level: 'SKU Level Competing only with FBM',
      original_store_level: 'Store Level Competing only with FBM',
      short: 'FBM',
    },
    compete_only_fba: {
      original_sku_level: 'SKU Level Competing only with FBA',
      original_store_level: 'Store Level Competing only with FBA',
      short: 'FBA',
    },
    buy_box_matching: {
      original_sku_level: 'SKU Level Buy Box Matching',
      original_store_level: 'Store Level Buy Box Matching',
      short: 'BBM',
    },
    buy_box_suppression_threshold: {
      original_sku_level: 'SKU Level Buy Box Suppression Threshold',
      original_store_level: 'Store Level Buy Box Suppression Threshold',
      short: 'BST',
    },
  },
};

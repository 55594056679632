import { STORE_OVERVIEW_PREFERENCES } from '@/constants';

export default {
  acosRoasSelect: 'ACoS',
  tacosTacopTprasSelect: 'TACoS',
  advertisingSalesAdvertisingOrdersSelect: 'Advertising Sales',
  organicSalesOrganicOrdersSelect: 'Organic Sales',
  totalSalesTotalOrdersSelect: 'Total Sales',
  activeMainPreferenceKeyList:
    JSON.parse(localStorage.getItem('activeMainPreferenceKeyList')) || [],
  activeAdvPreferenceKeyList: JSON.parse(
    localStorage.getItem('activeAdvPreferenceKeyList')
  ) || [STORE_OVERVIEW_PREFERENCES.PPC],
};

export default {
  terms: {
    download: 'Download',
    actions: 'Actions',
    user: 'User',
    completion_time: 'Completion time',
    status: 'Status',
    total: 'Total',
    column: 'Column',
    sticky_column: 'Sticky column',
    rows_per_page: 'Rows per page',
    options: 'Options',
    history: 'History',
    apply_filters: 'Apply filters',
    create_report: 'Create report',
    run_report: 'Run report',
    report: 'Report',
    search_report: 'Search report',
    report_type: '@:advertising.terms.report type',
    report_name: '@:advertising.terms.report name',
    report_period: '@:advertising.terms.report period',
    field_options: (ctx) =>
      `${ctx.named('field')} ${ctx
        .linked('general.terms.options')
        .toLowerCase()}`,
  },
  terminology: {
    portfolio: 'Portfolio',
    campaign: 'Campaign',
    ad_group: 'Ad group',
    target: 'Target',
    targets: 'Targets',
    acos: 'ACoS',
    tacos: 'TACoS',
    roas: 'RoAS',
    impressions: 'Impressions',
    clicks: 'Clicks',
    orders: 'Orders',
    market_share: 'Market Share',
    market_share_tooltip: 'Shows how much of the market your product captures',
    share_of_voice: 'Share of Voice',
    share_of_voice_tooltip:
      "Measures your brand's visibility compared to competitors.",

    cpc: 'CPC',
    cvr: 'CVR',
    ctr: 'CTR',
    aov: 'AoV',
    automation_status: 'Automation status',
    targeted_acos: 'Targeted ACoS',
    targeted_tacos: 'Targeted TACoS',
    amazon_bidding_strategy: 'Amazon Bidding Strategy',
    budget: 'Budget',
    bid: 'Bid',
    bid_adjustment: 'Bid adjustment',
    bidding: 'Bidding',
    strategy: 'Strategy',
    spend: 'Spend',
    sales: 'Sales',
    product: 'Product',
    product_name: '@:advertising.terminology.product name',
    asin: 'ASIN',
    asins: 'Asins',
    placements: 'Placements',
    target_type: '@:advertising.terminology.target type',
    campaign_type: '@:advertising.terminology.campaign type',
    campaign_bidding_strategy:
      '@:advertising.terminology.campaign bidding strategy',
    campaign_status: '@:advertising.terminology.campaign status',
    campaign_automation_status:
      '@:advertising.terminology.campaign automation status',
    inventory_status: 'Inventory status',
    expand_asins: 'Expand asins',
    expand_targets: 'Expand targets',
    expand_placements: 'Expand placements',
    cost: 'Cost',
    rank: 'BSR',
    adSales: 'Ad. Sales',
    totalSales: 'Total Sales',
    SP: 'Sponsored Products',
    SD: 'Sponsored Display',
    SB: 'Sponsored Brands',
    ST: 'Sponsored TV',
    start_date: 'Start Date',
  },
  components: {
    dashboard: {
      sync_from: 'Sync from',
      last_sync: 'Last Sync',
      next_sync: 'Next Sync',
      summary_title: 'Advertising Summary',
      summary_sub_title:
        'Store performance related to advertising in the selected period and the change ratios compared to the previous period.',
      overall_title: 'Advertising Overall Data',
      overall_sub_title:
        'Overall advertising performance metrics of the store for selected and previous periods.',
      conversion_rate_title: 'CVR and Impressions',
      conversion_rate_sub_title: 'Track impressions and conversion rates.',
      orders_and_performance_title: 'Orders and Performance Metrics',
      orders_and_performance_sub_title:
        'Track advertising performance with the help of order metrics.',
      click_sessions_and_ctr_title: 'Clicks, Sessions and CTR',
      click_sessions_and_ctr_sub_title:
        'Track clicks, sessions, and clicks through rates.',
      average_order_value_title: 'Average Order Value (AOV)',
      average_order_value_sub_title:
        'Track total and advertising average order value vs cost.',
      sales_spend_and_performance_title: 'Sales, Spend and Performance Metrics',
      sales_spend_and_performance_sub_title:
        'Track advertising performance with the help of sales and cost values.',
      syncing_from: 'Syncing from',
      choose_right_acc:
        "Selected Amazon advertising account does not have access for selected Eva store, can't connect advertising account. Please choose the right store and try again.",
      terminology: {
        Acos: 'ACoS',
        AdCr: 'Ad CR',
        AdTotalOrders: 'Ad. / Total Orders',
        AllCr: 'Total CVR',
        AverageCpc: 'Average CPC',
        AverageDailySales: 'Average Daily Sales',
        AverageDailySpend: 'Average Daily Spend',
        Clicks: 'Clicks',
        Ctr: 'CTR',
        Cvr: 'CVR',
        Impressions: 'Impressions',
        IntervalEndDate: 'Interval End Date',
        IntervalStartDate: 'Interval Start Date',
        OrganicOrders: 'Organic Orders',
        OrganicSales: 'Organic Sales',
        PpcOrders: 'Ad Orders',
        PpcSales: 'Ad Sales',
        Roas: 'ROAS',
        Sessions: 'Sessions',
        Spend: 'Spend',
        Tacos: 'TACoS',
        TotalAmount: 'Total Sales',
        TotalSales: 'Total Sales',
        AdSales: 'Ad Sales',
        TotalCost: 'Total Cost',
        OtherCost: 'Other Cost',
        AdSpend: 'Ad Spend',
        AdOrders: 'Ad Orders',
        TotalOrders: 'Total Orders',
        AdAov: 'Ad. AOV',
        TotalAov: 'Total AOV',
        AdCpo: 'Ad CPO',
        AdCost: 'Ad Spend',
        TotalNonAdExpense: 'Other Cost',
        /*Acop: 'ACoP',*/
        Tacop: 'TACoP',
        Pras: 'PRAS',
        Tpras: 'TPRAS',
      },
    },
    audit: {
      sales: {
        title: 'Ad Sales',
        hint: 'The sales impact of paid advertising.',
      },
      spend: {
        title: 'Spend',
        hint: 'The incurred cost associated with advertising activities.',
      },
      acos: {
        title: 'ACoS',
        hint: 'Advertising Cost of Sales (Ad Spend / Ad Sales) of the campaigns for the given period.',
      },
      roas: {
        title: 'Roas',
        hint: 'Return on Ad Spend (Ad Sales / Ad Spend) of the campaings for the given period.',
      },
      impressions: {
        title: 'Impressions',
        hint: 'The number of times an ad is displayed to a user.',
      },
      clicks: {
        title: 'Clicks',
        hint: 'The number of times users click on an ad.',
      },
      orders: {
        title: 'Ad Orders',
        hint: 'The orders impact of paid advertising.',
      },
    },
    connect_store: {
      you_have_not_adv_acc:
        "YOU HAVEN'T ADDED ANY AMAZON ADVERTISING ACCOUNT YET.",
      connect_adv_acc:
        'Please connect your Amazon Advertising Account to receive Eva Advertising Analytics data.',
      connect_ad_acc: 'Connect Ad Account',
    },
    sync_store: {
      syncing_with_amazon_ads_acc: 'Syncing with Amazon Ads Account',
      we_are_now_syncing_your_amz_acc:
        "We're now syncing with your Amazon Advertising Account.",
      it_may_take_a_while:
        "It may take a while, and we'll let you know when your account is ready.",
    },
    headers: {
      amazon_bidding_strategy: (ctx) =>
        `Amazon<br><div class='flex'><span>${ctx
          .linked('advertising.terminology.bidding')
          .toLowerCase()}</span>&nbsp;<span>${ctx
          .linked('advertising.terminology.strategy')
          .toLowerCase()}</span></div>`,
    },
    rule_parameters: {
      system_rules_enabled: 'System rules enabled',
      automation_target_type: 'Automation target type',
    },
  },
};
